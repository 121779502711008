<template>
    <Transition name="sp-modal">
        <div ref="cveDetail" tabindex="0" class="sp-modal-mask" @keydown.esc="$emit('close')">
            <div class="sp-modal-wrapper">
                <div class="sp-modal-container">
                    <div class="sp-modal-header">
                        <span class="sp-modal-header-title">{{ cveInfo.name }}</span>
                        <span tabindex="0" class="sp-modal-header-closebtn material-symbols-outlined" @click="$emit('close')">
                            Close
                        </span>
                    </div>
                    <div class="sp-modal-content">
                        <div class="sp-modal-info">
                            <div :style="severityStyle" class="sp-modal-info-description">
                                <div v-if="perplexityLoading" class="loading-spinner">Loading...</div>
                                <div v-else>
                                    {{ perplexityResponse || cveInfo.summary || 'Summary is not available' }}
                                    <button
                                        @click="getPerplexityAnalysis"
                                        class="perplexity-button"
                                        :disabled="perplexityLoading"
                                    >
                                        {{ perplexityResponse ? 'Refresh Analysis' : 'Get AI Analysis' }}
                                    </button>
                                </div>
                                <div v-if="citations && citations.length" class="citations-section">
                                <h4>References</h4>
                                <ul class="citations-list">
                                    <li v-for="(citation, index) in citations" :key="index">
                                        <a :href="citation" target="_blank" rel="noopener noreferrer">
                                            {{ formatCitationUrl(citation) }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            <div class="sp-modal-info-rank">
                                <div class="info-rank-table">
                                    <div class="info-rank-table-row">
                                        <div class="info-rank-table-cell">First found</div>
                                        <div class="info-rank-table-cell info-rank-value">{{ cveInfoDate }}</div>
                                    </div>
                                </div>
                                <div class="info-rank-table">
                                    <div class="info-rank-table-row">
                                        <div class="info-rank-table-cell">Link</div>
                                        <div class="info-rank-table-cell info-rank-value">
                                            <span v-if="cveInfo.link">
                                                <a :href="cveInfo.link" target="_blank">{{ cveInfo.name }}</a>
                                            </span>
                                            <span v-else>
                                                <a :href="generateCVELink(cveInfo.name)" target="_blank">{{ cveInfo.name
                                                    }}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-rank-table">
                                    <div class="info-rank-table-row">
                                        <div class="info-rank-table-cell">Severity</div>
                                        <div class="info-rank-table-cell info-rank-value">
                                            <span :class="severityLabelClass(cveInfo.severity.name)">{{
                                                cveInfo.severity.name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-rank-table">
                                    <div class="info-rank-table-row">
                                        <div class="info-rank-table-cell">CVSS v2</div>
                                        <div class="info-rank-table-cell info-rank-value">
                                            <span :class="severityCVSSClass(cveInfo.cvss2_score)">
                                                {{ cveInfo.cvss2_score || 'N/A' }} {{
                                                getCVSSSeverity(cveInfo.cvss2_score) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-rank-table">
                                    <div class="info-rank-table-row">
                                        <div class="info-rank-table-cell">CVSS v3</div>
                                        <div class="info-rank-table-cell info-rank-value">
                                            <span :class="severityCVSSClass(cveInfo.cvss3_score)">
                                                {{ cveInfo.cvss3_score || 'N/A' }} {{
                                                getCVSSSeverity(cveInfo.cvss3_score) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="sp-modal-details">
                            <CVEDetailDataList
                                :key=" 'component' + CVEDetailDataListKeys.componentKey"
                                list-header="Components"
                                list-id="component"
                                :selected-item="selectedItems.component"
                                :list-items="components"
                                @custom-event="getSeverityChange"
                                @custom-selection="getCustomSelection"
                            />

                            <CVEDetailDataList
                                :key="'version' + CVEDetailDataListKeys.versionKey"
                                list-header="Versions"
                                list-id="version"
                                :selected-item="selectedItems.version"
                                :list-items="versions"
                                @custom-event="getSeverityChange"
                                @custom-selection="getCustomSelection"
                            />

                            <CVEDetailDataList
                                :key="'artifact' + CVEDetailDataListKeys.artifactsKey"
                                list-header="Artifacts" list-id="artifact"
                                :selected-item="selectedItems.artifact" :list-items="artifacts"
                                @custom-event="getSeverityChange" @custom-selection="getCustomSelection"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
    <Teleport to="body">
        <SeverityChange v-if="showSeverityChange" :severity-change="SeverityChange" @close="closeSeverityChange" />
    </Teleport>
</template>

<script>
import SeverityChange from './SeverityChange.vue'
import CVEDetailDataList from './cveDetails/CVEDetailDataList.vue';

export default {
    name: 'CVEDetail',
    components: {
        SeverityChange,
        CVEDetailDataList
    },
    props: {
        cveInfoInit: {
            type: Object,
            default: null
        },
        selectedCveInfo: {
            type: Object,
            default: null
        },

    },
    data() {
        return {
            showSeverityChange: false,
            SeverityChange: null,
            cveInfo: this.cveInfoInit,
            artifactsList: null,
            CVEDetailDataListKeys: {
                componentKey: 0,
                versionKey: 1,
                artifactsKey: 2,

            },
            selectedItems: this.selectedCveInfo,
            perplexityResponse: null,
            perplexityLoading: false,
            citations: null,
        }
    },

    emits: ['close'],
    computed: {
        cveInfoDate() {
            const date = new Date(this.cveInfo.date)
            return date.toDateString()
        },
        severityStyle() {
            const severity = this.cveInfo.severity.name || 'none'
            return { 'border-left-color': `var(--color-severity-${severity.toLowerCase()})` }
        },
        isCustomer() { return this.$store.state.userProfile.customer },

        components() {
            return [ ... this.cveInfo?.components?.filter((obj, index, self)=> index === self.map(item=>item.name).indexOf(obj.name) ) ]
        },
        versions() {
            return [ ... this.cveInfo?.components?.filter((obj)=> obj.name === this.selectedItems.component ).map(item=> { return { id: item.id, name: item.version, severity: "any", clickItemName: this.cveInfo.name, clickItemDetails: JSON.parse(JSON.stringify(item)) } })]
        },
        artifacts() {
            return  this.artifactsList?.length?  [ ...this.artifactsList?.map(item => { return { id: item.image.id, name: item.image.name, severity: item.mira_severity.name, clickItemName: this.cveInfo.name, clickItemDetails: JSON.parse(JSON.stringify(item)) }})] : [];
        },

    },
    methods: {
        generateCVELink(cve_id) {
            return `https://nvd.nist.gov/vuln/detail/${cve_id}`
        },
        severityLabelClass(severity_name) {
            const severity = severity_name || 'none'
            return ['sp-severity-label', `sp-severity-label-${severity.toLowerCase()}`]
        },
        getCVSSSeverity(score) {
            const cvss_severities = ['Low', 'Low', 'Low', 'Low', 'Medium', 'Medium', 'Medium', 'High', 'High', 'Critical', 'Critical']
            if (score) {
                return cvss_severities[parseInt(score.split('.')[0])]
            } else {
                return ''
            }
        },
        severityCVSSClass(score) {
            let severity = 'none'
            if (score != '') {
                severity = this.getCVSSSeverity(score).toLowerCase()
            }
            return ['sp-severity-label', `sp-severity-label-${severity}`]
        },
        getSeverityChange(dataType, cve_name, element) {
            const component_name = element.name ? element.name : this.selectedItems.component
            const component_version = element.version ? element.version : this.selectedItems.version
            const component_id = this.cveInfo?.components.filter((el) => {
                return el.name == component_name && el.version == component_version
            })[0].id
            this.$data.SeverityChange = {
                dataType: dataType,
                cve_name: cve_name,
                component: element,
                componentDetails: {
                    id: component_id,
                    component: component_name,
                    version: component_version,
                }
            }
            this.$data.showSeverityChange = true
        },
        closeSeverityChange() {
            this.getArtifacts();
            this.$data.showSeverityChange = false
            this.$refs.cveDetail.focus()
        },
        updateCVEInfo() {
            const params = new URLSearchParams({cve_name: this.cveInfo.name })
            this.$axios.get('/api/v2/cveinfo/components', {params: params})
                .then((response) => {
                    this.cveInfo = response.data
                })
                .catch(({ response }) => { console.error(response) })
        },
        getCustomSelection(dataType, selection) {
            switch (dataType) {
                case 'component' :
                    this.selectedItems.component = selection;
                    this.selectedItems.version = this.versions[0].name;
                    this.CVEDetailDataListKeys.versionKey++;
                    this.getArtifacts();
                    break;
                case 'version' :
                    this.selectedItems.version = selection;
                    this.getArtifacts();
                    break;
                case 'artifact' :
                    this.selectedItems.artifact = selection;
                    break;
            }
        },
        getArtifacts() {
            const params = new URLSearchParams ({cve_name: this.cveInfo.name,
            component_name: this.selectedItems.component,
            component_version: this.selectedItems.version})

            this.$axios.get('/api/v2/cveinfo/images', { params: params })
                .then((response) => {
                    this.artifactsList = response.data;
                    this.CVEDetailDataListKeys.artifactsKey++;
                })
                .catch(({ response }) => { console.error(response) })
        },
        formatCitationUrl(url) {
            try {
                const hostname = new URL(url).hostname;
                return hostname.replace('www.', '');
            } catch (e) {
                return url;
            }
        },
        async getPerplexityAnalysis() {
            this.perplexityLoading = true;
            const params = new URLSearchParams ({ cve_name: this.cveInfo.name,
                cve_severity: this.cveInfo.severity.name})
            try {
                const response = await this.$axios.get('/api/ai/perplexity/reasessment', {
                    params: params
                });
                this.perplexityResponse = response.data.analysis;
                this.citations = response.data.citations;
            } catch (error) {
                console.error('Error fetching Perplexity analysis:', error);
                // Optionally show an error message to the user
            } finally {
                this.perplexityLoading = false;
            }
        }
    },
    beforeMount () {
        this.getArtifacts();
    },
    mounted() {
        this.$refs.cveDetail.focus()

    },
}
</script>

<style scoped>
.info-rank-table {
    display: table;
}

.info-rank-table-row {
    display: table-row;
}

.info-rank-table-cell {
    display: table-cell;
}

.info-rank-value {
    text-align: end;
}

.sp-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.sp-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.sp-modal-container {
    width: 80vw;
    height: 70vh;
    margin: 0px auto;
    border: 1px solid var(--color-border-header);
    border-radius: 3px;
    background-color: var(--color-bg);
    color: var(--color-fg);
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    overflow: hidden;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.sp-modal-header {
    height: 40px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    color: var(--color-fg-header);
    background-color: var(--color-bg-header);
    border-top: 1px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
}

.sp-modal-header-closebtn {
    cursor: pointer;
}

.sp-modal-content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: calc(100% - 80px);
}

.sp-modal-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.sp-modal-info-description {
    width: calc(100% - 240px);
    border-left: 5px solid;
    background-color: var(--color-bg-surface);
    border-top: 3px solid var(--color-border-header);
    border-radius: 3px;
    padding: 15px;
    overflow-y: auto;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    white-space: pre-wrap;
}

.sp-modal-info-rank {
    width: 220px;
    display: flex;
    flex-direction: column;
    line-height: 1.7em;
    text-decoration: none;
}

.sp-modal-info-rank a {
    text-decoration: none;
    font-weight: 600;
    color: var(--color-link);
}

.sp-modal-info-description,
.sp-modal-info-rank {
    background-color: var(--color-bg-surface);
    border-top: 3px solid var(--color-border-header);
    border-radius: 3px;
    padding: 10px;
}

.sp-modal-details {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: auto;
    gap: 0px 10px;
    flex-wrap: nowrap;
    align-items: stretch;
    height: calc(100% - 165px);
    overflow: hidden;
}

/* transition */

.sp-modal-enter-from {
    opacity: 0;
}

.sp-modal-leave-to {
    opacity: 0;
}

/*.sp-modal-enter-from .sp-modal-container,
.sp-modal-leave-to .sp-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}*/
.selectedItem {
    border: 1px solid #fff;
}

.perplexity-button {
    display: block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: var(--color-bg-header);
    color: var(--color-fg-header);
    border: 1px solid var(--color-border-header);
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.perplexity-button:hover {
    background-color: var(--color-bg-surface);
}

.perplexity-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

/* Scrollbar styling */
.sp-modal-info-description::-webkit-scrollbar {
    width: 8px;
}

.sp-modal-info-description::-webkit-scrollbar-track {
    background: var(--color-bg);
    border-radius: 4px;
}

.sp-modal-info-description::-webkit-scrollbar-thumb {
    background: var(--color-border-header);
    border-radius: 4px;
}

.sp-modal-info-description::-webkit-scrollbar-thumb:hover {
    background: var(--color-bg-header);
}
</style>
